.trading-view-container {
  /* display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; */
  padding: 10px;
  /* height: 100vh; */
  background-color: #f5f5f5;
}

.trading-view-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fffffff3;
}

.trading-view-timeout {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 10px;
}

.trading-view-timeout label {
  /* color: #ffffff; */
  font-weight: 600;
}

.trading-view-timeout input {
  padding: 5px;
  width: 150px;
  height: 20px;
  border-radius: 10px;
  border: none;
  background-color: #e8e7e7f3;
}

.trading-view-file-upload {
  margin-top: 12px;
  height: 500px;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.775);
  padding: 10px;
  border-radius: 15px;
}

.trading-view-file-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  padding: 0;
  margin-top: 15px;
}

.trading-view-file-item-green {
  /* background-color: #5cb85c; */
  background-color: #60c060ba;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s ease;
}

.trading-view-file-item-green:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}


.trading-view-file-item {
  padding: 10px;
  /* background-color: #fff; */
  background-color: #8d00004d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s ease;
}

.trading-view-file-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.trading-view-file-item-2 {
  padding: 10px;
  /* background-color: #0095a92b; */
  background-color: #528d004d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s ease;
}

.trading-view-file-item-2:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.trading-view-controls {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.trading-view-button {
  padding: 4px 10px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #0096a9;
  background-color: #0096a9;
  color: white;
  font-weight: 500;
}

:hover.trading-view-button {
  background-color: #0095a9dc;
}

.trading-start-button {
  padding: 4px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #0096a9;
  background-color: #0096a9;
  color: white;
  font-weight: 500;
}

:hover.trading-start-button {
  background-color: #0095a9dc;
}

.trading-stop-button {
  padding: 4px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid lightgray;
  background-color: rgb(200, 200, 200);
  color: gray;
  opacity: 0.8;
}

.trading-view-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.strategy-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.strategy-item {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* ---------------------------------------------------------------- search and checkbox start */

.checkbox-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.select-all {
  font-size: 16px;
  margin-right: 20px;
  cursor: pointer;
}

.select-all input {
  margin-right: 5px;
}

.search-label {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.search-label .search-input {
  margin-left: 8px;
  padding: 7px 10px 7px 10px;
  border: 1px solid #ccc;
  background-color: #e8e7e7f3;
  border-radius: 4px;
  outline: none;
  transition: border 0.3s;
}

.search-label .search-input:focus {
  border-color: rgb(0, 150, 169);
}

.previousClose-grid-container {
  display: grid;
  /* grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 50px); */
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  margin: 8px 8px 0px 8px;
}

.marketprice-grid-container {
  display: grid;
  /* grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 50px); */
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 8px 8px 0px 8px;
}

.previousClose-grid-item {
  display: flex;
  justify-content: start;
  align-items: start;
  background-color: #314d75;
  padding: 8px;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  /* cursor: pointer; */
  transition: background-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.marketprice-grid-item {
  display: flex;
  justify-content: start;
  align-items: start;
  background-color: rgb(0 150 169);
  padding: 8px;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  /* cursor: pointer; */
  transition: background-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.fiftyTwo-grid-item {
  display: flex;
  justify-content: start;
  align-items: start;
  background-color: #414f51;
  padding: 8px;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  /* cursor: pointer; */
  transition: background-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}


/* ---------------------------------------------------------------- search and checkbox end */

@media (max-width: 1200px) {
  .trading-view-file-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .strategy-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .trading-view-file-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .strategy-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .trading-view-file-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .strategy-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
